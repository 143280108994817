import * as React from "react";

import { Link } from "gatsby";
import Img from "gatsby-image";

export default function BlogPostCard({ post }) {
  return (
    

    
      <div className="bg-white relative h-full shadow-2xl transform hover:scale-110 transition-transform">
      
      <Link to={post.fields.slug}>
        
      <div class="grid grid-rows-4">
      
          <div className=" static inset-0 row-span-3 items-center pb-5 ">
            <Img
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
              className="w-full h-full"
              imgStyle={{
                zIndex: -1,
                filter: "brightness(1)"
              }}
            />
          </div>

      
         
                <div class="row-span-2">
                 <p className="blog-header relative inset-0 h-full flex justify-bottom px-5 text-black"> 
                  {post.frontmatter.title}
                 </p> 
                </div>

                <div class="row-span-3">
                  <p className="realtive inset-0 h-full flex justify-bottom px-5 text-left font-sans text-gray-500 pb-5">
                    {post.frontmatter.featuredText}
                  </p>
                </div> 
                
                <div class="row-span-4">
                  <p className="blog-author relative inset-0 h-full flex justify-bottom px-5 text-left font-sans font-style italic text-shadow pb-5">
                    {post.frontmatter.author}
                  </p>   
                </div>

              
                </div>
                         
                  
                    
             
             
             
          
               
        
          
          
                
      </Link>
     
    </div>
  );
}
