import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";

import BlogPostCard from "../../components/blogPostCard";

export default function SocialImpact() {
  const data = useStaticQuery(graphql`
    {
      posts: allFile(filter: { relativeDirectory: { regex: "/^blog/" } }) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                title
                featuredText
                author
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  `);

  const posts = data["posts"].edges;

  return (
    <Layout>
      <h2 className="pr-5 section-header text-center">Blog</h2>
      <div className="relative">
        <div className="absolute inset-0">
          {/* <Img
            fluid={background}
            alt="background"
            className="h-full"
            imgStyle={{
              zIndex: -1,
              objectPosition: "0% 0",
              filter: "brightness(0.6)",
            }}
          /> */}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-16 lg:gap-20 max-w-7xl m-auto pb-24 px-5 lg:px-16">
          {posts.map(({ node }, index) => (
            <BlogPostCard post={node.childMarkdownRemark} />
          ))}
        </div>
      </div>
    </Layout>
  );
}
